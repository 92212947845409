function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
var Subtitles = function () {
    function Subtitles(player) {
        var _this = this;
        _classCallCheck(this, Subtitles);
        this.player = player;
        this.player.template.mask.addEventListener('click', function () {
            _this.hide();
        });
        this.player.template.subtitlesButton.addEventListener('click', function () {
            _this.adaptiveHeight();
            _this.show();
        });
        var lastItemIndex = this.player.template.subtitlesItem.length - 1;
        var _loop = function _loop(i) {
            _this.player.template.subtitlesItem[i].addEventListener('click', function () {
                _this.hide();
                if (_this.player.options.subtitle.index !== i) {
                    _this.player.template.subtitle.innerHTML = '<p></p>';
                    _this.player.template.subtrack.src = _this.player.template.subtitlesItem[i].dataset.subtitle;
                    _this.player.options.subtitle.index = i;
                    if (_this.player.template.subtitle.classList.contains('dplayer-subtitle-hide')) {
                        _this.subContainerShow();
                    }
                }
            });
        };
        for (var i = 0; i < lastItemIndex; i++) {
            _loop(i);
        }
        this.player.template.subtitlesItem[lastItemIndex].addEventListener('click', function () {
            _this.hide();
            if (_this.player.options.subtitle.index !== lastItemIndex) {
                _this.player.template.subtitle.innerHTML = '<p></p>';
                _this.player.template.subtrack.src = '';
                _this.player.options.subtitle.index = lastItemIndex;
                _this.subContainerHide();
            }
        });
    }
    _createClass(Subtitles, [
        {
            key: 'subContainerShow',
            value: function subContainerShow() {
                this.player.template.subtitle.classList.remove('dplayer-subtitle-hide');
                this.player.events.trigger('subtitle_show');
            }
        },
        {
            key: 'subContainerHide',
            value: function subContainerHide() {
                this.player.template.subtitle.classList.add('dplayer-subtitle-hide');
                this.player.events.trigger('subtitle_hide');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.template.subtitlesBox.classList.remove('dplayer-subtitles-box-open');
                this.player.template.mask.classList.remove('dplayer-mask-show');
                this.player.controller.disableAutoHide = false;
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.template.subtitlesBox.classList.add('dplayer-subtitles-box-open');
                this.player.template.mask.classList.add('dplayer-mask-show');
                this.player.controller.disableAutoHide = true;
            }
        },
        {
            key: 'adaptiveHeight',
            value: function adaptiveHeight() {
                var curBoxHeight = this.player.template.subtitlesItem.length * 30 + 14;
                var stdMaxHeight = this.player.template.videoWrap.offsetHeight * 0.8;
                if (curBoxHeight >= stdMaxHeight - 50) {
                    this.player.template.subtitlesBox.style.bottom = '8px';
                    this.player.template.subtitlesBox.style['max-height'] = stdMaxHeight - 8 + 'px';
                } else {
                    this.player.template.subtitlesBox.style.bottom = '50px';
                    this.player.template.subtitlesBox.style['max-height'] = stdMaxHeight - 50 + 'px';
                }
            }
        }
    ]);
    return Subtitles;
}();
export default Subtitles;