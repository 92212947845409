function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
        throw new TypeError('Cannot call a class as a function');
    }
}
function _defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ('value' in descriptor)
            descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
    }
}
function _createClass(Constructor, protoProps, staticProps) {
    if (protoProps)
        _defineProperties(Constructor.prototype, protoProps);
    if (staticProps)
        _defineProperties(Constructor, staticProps);
    Object.defineProperty(Constructor, 'prototype', { writable: false });
    return Constructor;
}
import utils from './utils';
import Thumbnails from './thumbnails';
import Icons from './icons';
import { Subject } from 'rxjs';
var cast;
var runOnce = true;
var isCasting = false;
var Controller = function () {
    function Controller(player) {
        _classCallCheck(this, Controller);
        this.player = player;
        this.autoHideTimer = 0;
        if (!utils.isMobile) {
            this.setAutoHideHandler = this.setAutoHide.bind(this);
            this.player.container.addEventListener('mousemove', this.setAutoHideHandler);
            this.player.container.addEventListener('click', this.setAutoHideHandler);
            this.player.on('play', this.setAutoHideHandler);
            this.player.on('pause', this.setAutoHideHandler);
        }
        this.initPlayButton();
        this.initThumbnails();
        this.initPlayedBar();
        this.initFullButton();
        this.initQualityButton();
        this.initScreenshotButton();
        if (this.player.options.subtitle) {
            if (typeof this.player.options.subtitle.url === 'string') {
                this.initSubtitleButton();
            }
        }
        this.initHighlights();
        this.initAirplayButton();
        this.initChromecastButton();
        if (!utils.isMobile) {
            this.initVolumeButton();
        }
    }
    _createClass(Controller, [
        {
            key: 'initPlayButton',
            value: function initPlayButton() {
                var _this = this;
                this.player.template.playButton.addEventListener('click', function () {
                    _this.player.toggle();
                });
                this.player.template.mobilePlayButton.addEventListener('click', function () {
                    _this.player.toggle();
                });
                if (!utils.isMobile) {
                    if (!this.player.options.preventClickToggle) {
                        this.player.template.videoWrap.addEventListener('click', function () {
                            _this.player.toggle();
                        });
                        this.player.template.controllerMask.addEventListener('click', function () {
                            _this.player.toggle();
                        });
                    }
                } else {
                    this.player.template.videoWrap.addEventListener('click', function () {
                        _this.toggle();
                    });
                    this.player.template.controllerMask.addEventListener('click', function () {
                        _this.toggle();
                    });
                }
            }
        },
        {
            key: 'initHighlights',
            value: function initHighlights() {
                var _this2 = this;
                this.player.on('durationchange', function () {
                    if (_this2.player.video.duration !== 1 && _this2.player.video.duration !== Infinity) {
                        if (_this2.player.options.highlight) {
                            var highlights = _this2.player.template.playedBarWrap.querySelectorAll('.dplayer-highlight');
                            [].slice.call(highlights, 0).forEach(function (item) {
                                _this2.player.template.playedBarWrap.removeChild(item);
                            });
                            for (var i = 0; i < _this2.player.options.highlight.length; i++) {
                                if (!_this2.player.options.highlight[i].text || !_this2.player.options.highlight[i].time) {
                                    continue;
                                }
                                var p = document.createElement('div');
                                p.classList.add('dplayer-highlight');
                                p.style.left = _this2.player.options.highlight[i].time / _this2.player.video.duration * 100 + '%';
                                p.innerHTML = '<span class="dplayer-highlight-text">' + _this2.player.options.highlight[i].text + '</span>';
                                _this2.player.template.playedBarWrap.insertBefore(p, _this2.player.template.playedBarTime);
                            }
                        }
                    }
                });
            }
        },
        {
            key: 'initThumbnails',
            value: function initThumbnails() {
                var _this3 = this;
                if (this.player.options.video.thumbnails) {
                    this.thumbnails = new Thumbnails({
                        container: this.player.template.barPreview,
                        barWidth: this.player.template.barWrap.offsetWidth,
                        url: this.player.options.video.thumbnails,
                        events: this.player.events
                    });
                    this.player.on('loadedmetadata', function () {
                        _this3.thumbnails.resize(160, _this3.player.video.videoHeight / _this3.player.video.videoWidth * 160, _this3.player.template.barWrap.offsetWidth);
                    });
                }
            }
        },
        {
            key: 'initPlayedBar',
            value: function initPlayedBar() {
                var _this4 = this;
                var thumbMove = function thumbMove(e) {
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this4.player.template.playedBarWrap)) / _this4.player.template.playedBarWrap.clientWidth;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this4.player.bar.set('played', percentage, 'width');
                    _this4.player.template.ptime.innerHTML = utils.secondToTime(percentage * _this4.player.video.duration);
                };
                var thumbUp = function thumbUp(e) {
                    document.removeEventListener(utils.nameMap.dragEnd, thumbUp);
                    document.removeEventListener(utils.nameMap.dragMove, thumbMove);
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this4.player.template.playedBarWrap)) / _this4.player.template.playedBarWrap.clientWidth;
                    percentage = Math.max(percentage, 0);
                    percentage = Math.min(percentage, 1);
                    _this4.player.bar.set('played', percentage, 'width');
                    _this4.player.seek(_this4.player.bar.get('played') * _this4.player.video.duration);
                    _this4.player.timer.enable('progress');
                };
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragStart, function () {
                    _this4.player.timer.disable('progress');
                    document.addEventListener(utils.nameMap.dragMove, thumbMove);
                    document.addEventListener(utils.nameMap.dragEnd, thumbUp);
                });
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragMove, function (e) {
                    if (_this4.player.video.duration) {
                        var px = _this4.player.template.playedBarWrap.getBoundingClientRect().left;
                        var tx = (e.clientX || e.changedTouches[0].clientX) - px;
                        if (tx < 0 || tx > _this4.player.template.playedBarWrap.offsetWidth) {
                            return;
                        }
                        var time = _this4.player.video.duration * (tx / _this4.player.template.playedBarWrap.offsetWidth);
                        if (utils.isMobile) {
                            _this4.thumbnails && _this4.thumbnails.show();
                        }
                        _this4.thumbnails && _this4.thumbnails.move(tx);
                        _this4.player.template.playedBarTime.style.left = ''.concat(tx - (time >= 3600 ? 25 : 20), 'px');
                        _this4.player.template.playedBarTime.innerText = utils.secondToTime(time);
                        _this4.player.template.playedBarTime.classList.remove('hidden');
                    }
                });
                this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragEnd, function () {
                    if (utils.isMobile) {
                        _this4.thumbnails && _this4.thumbnails.hide();
                    }
                });
                if (!utils.isMobile) {
                    this.player.template.playedBarWrap.addEventListener('mouseenter', function () {
                        if (_this4.player.video.duration) {
                            _this4.thumbnails && _this4.thumbnails.show();
                            _this4.player.template.playedBarTime.classList.remove('hidden');
                        }
                    });
                    this.player.template.playedBarWrap.addEventListener('mouseleave', function () {
                        if (_this4.player.video.duration) {
                            _this4.thumbnails && _this4.thumbnails.hide();
                            _this4.player.template.playedBarTime.classList.add('hidden');
                        }
                    });
                }
            }
        },
        {
            key: 'initFullButton',
            value: function initFullButton() {
                var _this5 = this;
                this.player.template.browserFullButton.addEventListener('click', function () {
                    _this5.player.fullScreen.toggle('browser');
                });
                this.player.template.webFullButton.addEventListener('click', function () {
                    _this5.player.fullScreen.toggle('web');
                });
            }
        },
        {
            key: 'initVolumeButton',
            value: function initVolumeButton() {
                var _this6 = this;
                var vWidth = 35;
                var volumeMove = function volumeMove(event) {
                    var e = event || window.event;
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this6.player.template.volumeBarWrap) - 5.5) / vWidth;
                    _this6.player.volume(percentage);
                };
                var volumeUp = function volumeUp() {
                    document.removeEventListener(utils.nameMap.dragEnd, volumeUp);
                    document.removeEventListener(utils.nameMap.dragMove, volumeMove);
                    _this6.player.template.volumeButton.classList.remove('dplayer-volume-active');
                };
                this.player.template.volumeBarWrapWrap.addEventListener('click', function (event) {
                    var e = event || window.event;
                    var percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(_this6.player.template.volumeBarWrap) - 5.5) / vWidth;
                    _this6.player.volume(percentage);
                });
                this.player.template.volumeBarWrapWrap.addEventListener(utils.nameMap.dragStart, function () {
                    document.addEventListener(utils.nameMap.dragMove, volumeMove);
                    document.addEventListener(utils.nameMap.dragEnd, volumeUp);
                    _this6.player.template.volumeButton.classList.add('dplayer-volume-active');
                });
                this.player.template.volumeButtonIcon.addEventListener('click', function () {
                    if (_this6.player.video.muted) {
                        _this6.player.video.muted = false;
                        _this6.player.switchVolumeIcon();
                        _this6.player.bar.set('volume', _this6.player.volume(), 'width');
                    } else {
                        _this6.player.video.muted = true;
                        _this6.player.template.volumeIcon.innerHTML = Icons.volumeOff;
                        _this6.player.bar.set('volume', 0, 'width');
                    }
                });
            }
        },
        {
            key: 'initQualityButton',
            value: function initQualityButton() {
                var _this7 = this;
                if (this.player.options.video.quality) {
                    this.player.template.qualityList.addEventListener('click', function (e) {
                        if (e.target.classList.contains('dplayer-quality-item')) {
                            _this7.player.switchQuality(e.target.dataset.index);
                        }
                    });
                }
            }
        },
        {
            key: 'initScreenshotButton',
            value: function initScreenshotButton() {
                var _this8 = this;
                if (this.player.options.screenshot) {
                    this.player.template.camareButton.addEventListener('click', function () {
                        var canvas = document.createElement('canvas');
                        canvas.width = _this8.player.video.videoWidth;
                        canvas.height = _this8.player.video.videoHeight;
                        canvas.getContext('2d').drawImage(_this8.player.video, 0, 0, canvas.width, canvas.height);
                        var dataURL;
                        canvas.toBlob(function (blob) {
                            dataURL = URL.createObjectURL(blob);
                            var link = document.createElement('a');
                            link.href = dataURL;
                            link.download = 'DPlayer.png';
                            link.style.display = 'none';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(dataURL);
                            _this8.player.events.trigger('screenshot', dataURL);
                        });
                    });
                }
            }
        },
        {
            key: 'initAirplayButton',
            value: function initAirplayButton() {
                if (this.player.options.airplay) {
                    if (window.WebKitPlaybackTargetAvailabilityEvent) {
                        this.player.video.addEventListener('webkitplaybacktargetavailabilitychanged', function (event) {
                            switch (event.availability) {
                            case 'available':
                                this.template.airplayButton.disable = false;
                                break;
                            default:
                                this.template.airplayButton.disable = true;
                            }
                            this.template.airplayButton.addEventListener('click', function () {
                                this.video.webkitShowPlaybackTargetPicker();
                            }.bind(this));
                        }.bind(this.player));
                    } else {
                        this.player.template.airplayButton.style.display = 'none';
                    }
                }
            }
        },
        {
            key: 'initChromecast',
            value: function initChromecast() {
                var script = window.document.createElement('script');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('src', 'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1');
                window.document.body.appendChild(script);
                window.__onGCastApiAvailable = function (isAvailable) {
                    if (isAvailable) {
                        cast = window.chrome.cast;
                        var sessionRequest = new cast.SessionRequest(cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID);
                        var apiConfig = new cast.ApiConfig(sessionRequest, function () {
                        }, function (status) {
                            if (status === cast.ReceiverAvailability.AVAILABLE) {
                                console.log('chromecast: ', status);
                            }
                        });
                        cast.initialize(apiConfig, function () {
                        });
                    }
                };
            }
        },
        {
            key: 'initChromecastButton',
            value: function initChromecastButton() {
                var _this9 = this;
                if (this.player.options.chromecast) {
                    if (runOnce) {
                        runOnce = false;
                        this.initChromecast();
                    }
                    var discoverDevices = function discoverDevices() {
                        var subj = new Subject();
                        cast.requestSession(function (s) {
                            _this9.session = s;
                            subj.next('CONNECTED');
                            launchMedia(_this9.player.options.video.url);
                        }, function (err) {
                            if (err.code === 'cancel') {
                                _this9.session = undefined;
                                subj.next('CANCEL');
                            } else {
                                console.error('Error selecting a cast device', err);
                            }
                        });
                        return subj;
                    };
                    var launchMedia = function launchMedia(media) {
                        var mediaInfo = new cast.media.MediaInfo(media);
                        var request = new cast.media.LoadRequest(mediaInfo);
                        if (!_this9.session) {
                            window.open(media);
                            return false;
                        }
                        _this9.session.loadMedia(request, onMediaDiscovered.bind(_this9, 'loadMedia'), onMediaError).play();
                        return true;
                    };
                    var onMediaDiscovered = function onMediaDiscovered(how, media) {
                        _this9.currentMedia = media;
                    };
                    var onMediaError = function onMediaError(err) {
                        console.error('Error launching media', err);
                    };
                    this.player.template.chromecastButton.addEventListener('click', function () {
                        if (isCasting) {
                            isCasting = false;
                            _this9.currentMedia.stop();
                            _this9.session.stop();
                            _this9.initChromecast();
                        } else {
                            isCasting = true;
                            discoverDevices();
                        }
                    });
                }
            }
        },
        {
            key: 'initSubtitleButton',
            value: function initSubtitleButton() {
                var _this10 = this;
                this.player.events.on('subtitle_show', function () {
                    _this10.player.template.subtitleButton.dataset.balloon = _this10.player.tran('hide-subs');
                    _this10.player.template.subtitleButtonInner.style.opacity = '';
                    _this10.player.user.set('subtitle', 1);
                });
                this.player.events.on('subtitle_hide', function () {
                    _this10.player.template.subtitleButton.dataset.balloon = _this10.player.tran('show-subs');
                    _this10.player.template.subtitleButtonInner.style.opacity = '0.4';
                    _this10.player.user.set('subtitle', 0);
                });
                this.player.template.subtitleButton.addEventListener('click', function () {
                    _this10.player.subtitle.toggle();
                });
            }
        },
        {
            key: 'setAutoHide',
            value: function setAutoHide() {
                var _this11 = this;
                this.show();
                clearTimeout(this.autoHideTimer);
                this.autoHideTimer = setTimeout(function () {
                    if (_this11.player.video.played.length && !_this11.player.paused && !_this11.disableAutoHide) {
                        _this11.hide();
                    }
                }, 3000);
            }
        },
        {
            key: 'show',
            value: function show() {
                this.player.container.classList.remove('dplayer-hide-controller');
            }
        },
        {
            key: 'hide',
            value: function hide() {
                this.player.container.classList.add('dplayer-hide-controller');
                this.player.setting.hide();
                this.player.comment && this.player.comment.hide();
            }
        },
        {
            key: 'isShow',
            value: function isShow() {
                return !this.player.container.classList.contains('dplayer-hide-controller');
            }
        },
        {
            key: 'toggle',
            value: function toggle() {
                if (this.isShow()) {
                    this.hide();
                } else {
                    this.show();
                }
            }
        },
        {
            key: 'destroy',
            value: function destroy() {
                if (!utils.isMobile) {
                    this.player.container.removeEventListener('mousemove', this.setAutoHideHandler);
                    this.player.container.removeEventListener('click', this.setAutoHideHandler);
                }
                clearTimeout(this.autoHideTimer);
            }
        }
    ]);
    return Controller;
}();
export default Controller;